import React from "react"
import { useState } from "react"
import Navigation from "./Navigation"

const Header = () => {
  const [navActive, setNavActive] = useState(false)

  const toggle = () => {
    setNavActive(!navActive)
  }

  return (
    <header>
      <div className="px-5 py-4 bg-white border-b-2 md:pt-10">
        <div className="max-w-5xl mx-auto text-lg text-center uppercase text-green">

          <button
            onClick={toggle}
            className={`${navActive && "is-active"} text-green c-menu md:hidden w-12 h-12 inline-flex items-center justify-center`}
          >
            <span className="relative inline-block w-10 h-10">
              <span className="c-menu__item c-menu__item--first"></span>
              <span className="c-menu__item c-menu__item--mid"></span>
              <span className="c-menu__item c-menu__item--last"></span>
              <span className={`${!navActive ? "sr-only" : "hidden"}`}>Avaa valikko</span>
              <span className={`${navActive ? "sr-only" : "hidden"}`}>Sulje valikko</span>
            </span>
          </button>

          <div className={`${!navActive && "hidden"} md:block`}>
            <Navigation navigation="primary" spacing="8" ariaLabel="Päävalikko" />
          </div>

          <button
            onClick={toggle}
            className="sr-only"
          >
            Sulje valikko
          </button>

        </div>
      </div>
    </header>
  )
}

export default Header
