import { useStaticQuery, graphql } from "gatsby"

export const useNavData = () => {
  const { allContentfulNavigation } = useStaticQuery(
    graphql`
      query nav {
        allContentfulNavigation {
          edges {
            node {
              id
              title
              pages {
                slug
                title
              }
            }
          }
        }
      }
    `
  )
  return allContentfulNavigation.edges
}
