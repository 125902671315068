import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import getContentNode from "../utilities/contentNode"
import RichText from "./RichText"

const Content = ({ nodes, id }) => {
  const node = getContentNode(nodes, id)

  if (!node) {
    return <></>
  }

  const { textContent } = node

  return (
    <div className="max-w-3xl mx-auto mb-6 prose sm:max-w-3xl sm:prose-lg c-content">
      <div className="md:mx-4">
        <RichText content={textContent} />
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    nodes: state.app.content,
  }),
  null
)(Content)

Content.propTypes = {
  nodes: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
}
