import React from "react"
import { PropTypes } from "prop-types"
import { connect } from "react-redux"
import getContentNode from "../utilities/contentNode"
import RichText from "./RichText"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const SplitImageText = ({ nodes, id }) => {
  const node = getContentNode(nodes, id)

  if (!node) {
    return <></>
  }

  const { title, textContent, image } = node

  const img = getImage(image)

  return (
    <div className="flex flex-col mb-6 md:flex-row">
      <div className="md:w-2/4">
        <GatsbyImage image={img} alt={title} />
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 md:w-2/4">
        <div className="prose sm:prose-lg">
          <h2>{title}</h2>
          <div>
            <RichText content={textContent} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    nodes: state.app.splitImageText,
  }),
  null
)(SplitImageText)

SplitImageText.propTypes = {
  nodes: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
}
