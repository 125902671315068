import React from "react"
import Hero from "../components/Hero"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SkipToContent from "../components/SkipToContent"
import SEO from "../components/SEO"
import CookieBanner from "../components/CookieBanner"
import { connect } from "react-redux"
import {
  initPages,
  initContent,
  initHeading,
  initTeasers,
  initSplitImageText,
} from "../state/app"
import PropTypes from "prop-types"
import { useLayout } from "../hooks/layout"
import { usePage } from "../hooks/page"

const Layout = ({
  title,
  hero,
  children,
  seoTitle,
  seoDescription,
  slug,
  dispatch,
}) => {
  const pages = usePage()
  const { content, heading, splitImageText, teasers } = useLayout()

  dispatch(initPages(pages.edges))
  dispatch(initContent(content.edges))
  dispatch(initHeading(heading.edges))
  dispatch(initTeasers(teasers.edges))
  dispatch(initSplitImageText(splitImageText.edges))

  return (
    <div className="flex flex-col min-h-screen">

      <CookieBanner />

      <SkipToContent />

      <SEO title={title} seoTitle={seoTitle} seoDescription={seoDescription} slug={slug} />


      <Header />

      <main id="main" tabIndex="-1" className="flex-grow">
        {hero && <Hero {...hero} />}

        <div className="max-w-5xl mx-auto mt-10">
          <div className="mx-4">{children}</div>
        </div>
      </main>

      <Footer />

    </div>
  )
}

export default connect(null, null)(Layout)

Layout.propTypes = {
  title: PropTypes.string,
  hero: PropTypes.object,
  children: PropTypes.array,
  dispatch: PropTypes.func,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  slug: PropTypes.string
}
