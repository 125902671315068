import PropTypes from "prop-types"
import { path, head, filter } from "ramda"

const getContentNode = (nodes, id) => {
  const matchID = ({ node }) => node.id === id
  return path(["node"], head(filter(matchID, nodes)))
}

export default getContentNode

getContentNode.propTypes = {
  nodes: PropTypes.array.isRequired,
}
