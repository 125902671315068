import React from "react"
import { PropTypes } from "prop-types"
import { Link } from "gatsby"
import getNav from "../utilities/navigation"

const Navigation = ({ navigation, spacing, ariaLabel }) => {
  const items = getNav(navigation)

  const navItems =
    items &&
    items.map(({ slug, title }, index) => {
      const to = slug === "/" ? slug : `/${slug}`
      return (
        <li key={index} className={`mx-${spacing} my-4 md:my-0`}>
          <Link to={to}>{title}</Link>
        </li>
      )
    })

  return (
    <nav aria-label={ariaLabel}>
      <ul
        className={
          "flex flex-col md:flex-row justify-center align-middle -mx-4"
        }
      >
        {navItems}
      </ul>
    </nav>
  )
}

export default Navigation

Navigation.defaultProps = {
  spacing: "4",
  ariaLabel: "Valikko",
}

Navigation.propTypes = {
  navigation: PropTypes.string.isRequired,
  spacing: PropTypes.string,
  ariaLabel: PropTypes.string,
}
