import { useNavData } from "../hooks/navigation"
import PropTypes from "prop-types"
import { path } from "ramda"

const getNav = (key) => {
  const items = useNavData()
  const nav = items.find(({ node }) => node.title.toLowerCase() === key)

  return path(["node", "pages"], nav)
}

export default getNav

getNav.prototypes = {
  key: PropTypes.string.isRequired,
}
