import React from "react"
import { PropTypes } from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Teaser = ({ image, title, content, linkText, linkURL }) => {
  if (!image) {
    return <></>
  }

  const returnLink = (uri) => {
    if(!uri.includes("http")) {
      return (
        <span>
          <Link to={uri}>{linkText}</Link>
        </span>
      )
    } else {
      return (
        <span>
          <a href={uri} target="_blank" rel="noreferrer noopener">{linkText}</a>
        </span>
      )
    }
  }


  const img = getImage(image)

  return (
    <div className="relative">
      <GatsbyImage image={img} alt={title} />

      <div>
        <h3>{title}</h3>

        {content && content.content && <p>{content.content}</p>}

        {returnLink(linkURL)}
      </div>
    </div>
  )
}

export default Teaser

Teaser.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
}
