import { useStaticQuery, graphql } from "gatsby"

export const useLayout = () => {
  // prettier-ignore
  const data = useStaticQuery(
    graphql`
      query layoutQuery {
        content: allContentfulContent {
          edges {
            node {
              id
              title
              textContent {
                raw
                references {
                  ... on ContentfulAsset {
                    __typename
                    contentful_id
                    title
                    gatsbyImageData(width: 800, backgroundColor: "#E1E3E4", placeholder: NONE)
                  }
                }
              }
            }
          }
        }
        heading: allContentfulHeading {
          edges {
            node {
              id
              heading
            }
          }
        }
        splitImageText: allContentfulSplitImageText {
          edges {
            node {
              id
              title
              textContent {
                raw
              }
              image {
                gatsbyImageData(aspectRatio: 1.3333, width: 750, height: 575)
                title
              }
            }
          }
        }
        teasers: allContentfulTeasers {
          edges {
            node {
              id
              title
              teasers {
                image {
                  gatsbyImageData(aspectRatio: 1.3333, width: 750, height: 575)
                  title
                }
                title
                content {
                  content
                }
                linkText
                linkURL
              }
            }
          }
        }
      }
    `
  )
  return data
}
