import React from "react"
import { PropTypes } from "prop-types"
import Teaser from "./Teaser"
import { connect } from "react-redux"
import getContentNode from "../utilities/contentNode"

const Teasers = ({ nodes, id }) => {
  const node = getContentNode(nodes, id)

  if (!node) {
    return <></>
  }

  const { teasers, title } = node

  const items = teasers.map((teaser, index) => (
    <div
      className="w-full my-2 md:mx-2 md:w-1/3 md:my-0"
      key={index}
    >
      <Teaser {...teaser} />
    </div>
  ))

  return (
    <div className="mb-6 prose max-w-none">
      {title && <h2 className="text-center">{title}</h2>}
      {items && (
        <div className="flex flex-col justify-between mx-auto mb-6 align-top md:-mx-2 md:flex-row">
          {items}
        </div>
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    nodes: state.app.teasers,
  }),
  null
)(Teasers)

Teasers.propTypes = {
  nodes: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}
