export const isUriExternal = (uri) => {
  // prettier-ignore
  const protocols = [
    "mailto:",
    "https:",
    "http:",
    "ftp:"
  ]

  try {
    const url = new URL(uri)
    return protocols.includes(url.protocol)
  } catch (e) {
    return false
  }
}
