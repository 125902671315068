import React from "react"
import { PropTypes } from "prop-types"

const VideoEmbed = ({ uri, title }) => {
  return (
    <span className="relative block c-embed--video">
      <iframe
        title={title}
        src={uri}
        frameBorder="0"
        allowFullScreen
        className="absolute inset-0 w-full h-full"
      ></iframe>
    </span>
  )
}

export default VideoEmbed

VideoEmbed.propTypes = {
  uri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
