import React from "react"

const SkipToContent = () => (
  <div className="absolute top-0 left-0">
    <a
      href="#main"
      className="sr-only focus:not-sr-only focus:outline-none under"
    >
      <span className="inline-block px-3 py-2 m-2 text-white underline bg-green group-focus:ring group-focus:ring-inset">
        Hyppää sisältöön
      </span>
    </a>
  </div>
)
export default SkipToContent
