import React from "react"
import { PropTypes } from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useSeparator } from "../hooks/separator"

const Hero = ({ title, image }) => {
  if (!image) {
    return <></>
  }

  const getBgImage = (image) => {
    const img = getImage(image)
    return convertToBgImage(img)
  }

  const img = getImage(image)

  const separatorImage = useSeparator()

  const separatorBackground = getBgImage(separatorImage)

  return (
    <div className="c-hero bg-green relative">

      <GatsbyImage image={img} alt={title} className="w-full" />

      <div className="relative flex flex-row max-w-screen-xl pt-16 pb-24 mx-auto">
        <div className="absolute bottom-0 left-0 mb-8 ml-4 xl:ml-40">
          <StaticImage
            src="../images/tammi.png"
            alt="Tammi kuva"
            layout="fixed"
          />
        </div>
      </div>
      <div className="absolute bottom-0 w-full ">
        <BackgroundImage
          className="h-3 bg-repeat-x bg-auto bg-center-10"
          {...separatorBackground}
        />
      </div>
    </div>
  )

}

export default Hero

Hero.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  image: PropTypes.object,
}
