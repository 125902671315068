import React from "react"
import { graphql } from "gatsby"
import { path } from "ramda"
import PropTypes from "prop-types"
import Layout from "../containers/layout"
import ComponentPicker from "../components/ComponentPicker"

const DefaultTemplate = ({ data }) => {
  const {
    hero,
    components,
    title,
    seoTitle,
    seoDescription,
    slug
  } = path(["contentfulPage"], data)

  const componentItems =
    components &&
    components.map((component, index) => (
      <ComponentPicker
        key={index}
        type={component.internal.type}
        component={component}
      />
    ))

  return (
    <Layout
      title={title}
      hero={hero}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      slug={slug}
    >
      {componentItems}
    </Layout>
  )
}

export default DefaultTemplate

DefaultTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const defaultTemplateQuery = graphql`
  query defaultTemplateQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      internal {
        type
      }
      title
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      hero {
        title
        lead
        image {
          gatsbyImageData(height: 630, quality: 90)
        }
      }
      components {
        ... on ContentfulContent {
          id
          internal {
            type
          }
        }
        ... on ContentfulHeading {
          id
          internal {
            type
          }
        }
        ... on ContentfulSplitImageText {
          id
          internal {
            type
          }
        }
        ... on ContentfulTeasers {
          id
          internal {
            type
          }
        }
      }
    }
  }
`
