import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import GetMicrocopy from "../utilities/microcopy"
import { useSiteMetadata } from "../hooks/siteMetadata"

function SEO({ lang, meta, title, image, seoTitle, seoDescription, slug }) {
  const siteMetadata = useSiteMetadata()
  const metaTitle = seoTitle || title
  const metaImage = image || siteMetadata.image
  let metaDescription = GetMicrocopy("seo.description")

  if (seoDescription && seoDescription.seoDescription) {
    metaDescription = seoDescription.seoDescription
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${siteMetadata.title}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:image",
          content: metaImage,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "Lehtolainen",
        },
        {
          property: "og:url",
          content: `https://www.lehtolainen.com${slug === "/" ? "":"/"}${slug}`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: metaTitle,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

export default SEO

SEO.defaultProps = {
  lang: "fi",
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  slug: PropTypes.string
}
