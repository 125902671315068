import { useStaticQuery, graphql } from "gatsby"

export const useMicrocopyData = () => {
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query microcopy {
        allContentfulMicrocopy {
          edges {
            node {
              key
              value
            }
          }
        }
      }
    `
  )
  return allContentfulMicrocopy.edges
}
