import React from "react"
import { Link } from "gatsby"
import { PropTypes } from "prop-types"
import { path } from "ramda"
import { usePage } from "../hooks/page"
import { isUriExternal } from "../utilities/link"

const PageLink = ({ node, children }) => {
  const id = path(["data", "target", "sys", "id"], node)

  const pages = usePage()

  const page = pages.find(({ node }) => node.contentful_id === id)
  const slug = path(["node", "slug"], page)

  if (!slug) {
    return <></>
  }

  const to = slug === "/" ? slug : `/${slug}`

  return <Link to={`${to}`}>{children}</Link>
}

PageLink.propTypes = {
  node: PropTypes.object,
  children: PropTypes.array,
}

const DynamicLink = ({ node, uri, children, className }) => {
  if (node) {
    return <PageLink node={node}>{children}</PageLink>
  }

  if (!uri) {
    return <>{children}</>
  }

  const isExternal = isUriExternal(uri)

  if (isExternal) {
    return (
      <a href={uri} target="_blank" className={className} rel="noreferrer">
        {children}
        <span className="sr-only">
          (Avautuu uuteen ikkunaan)
        </span>
      </a>
    )
  }

  return (
    <span className={className}>
      <Link to={uri}>{children}</Link>
    </span>
  )
}

export default DynamicLink

DynamicLink.propTypes = {
  uri: PropTypes.string,
  node: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  className: PropTypes.string,
}

DynamicLink.defaultProps = {
  className: "",
}
