import React from "react"
import { PropTypes } from "prop-types"
import VideoEmbed from "../../components/VideoEmbed"
import DynamicLink from "../../components/DynamicLink"

// prettier-ignore
const domains = [
  "player.vimeo.com/video",
  "youtube.com/embed",
]

const HyperlinkResolver = ({ node, children }) => {
  const { data, content } = node

  if (domains.find((host) => data.uri.includes(host))) {
    const title = content
      .map((item) => item.nodeType === "text" && item.value)
      .join(" ")
    return <VideoEmbed uri={data.uri} title={title} />
  }

  return <DynamicLink uri={data.uri}>{children}</DynamicLink>
}

export default HyperlinkResolver

HyperlinkResolver.propTypes = {
  node: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
}
