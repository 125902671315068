import React from "react"
import { path } from "ramda"
import { PropTypes } from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const EmbeddedAsset = ({ node, content }) => {
  if (node.nodeType !== "embedded-asset-block") {
    return <></>
  }

  const assetId = path(["data", "target", "sys", "id"], node)
  const asset = content.references.find(
    (item) => item.contentful_id === assetId
  )

  if (!asset) {
    return <></>
  }

  const alt = path(["title"], asset)
  const gatsbyImageData = path(["gatsbyImageData"], asset)
  const image = getImage(gatsbyImageData)

  return (
    <GatsbyImage image={image} alt={alt} />
  )
}

export default EmbeddedAsset

EmbeddedAsset.propTypes = {
  node: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
}
