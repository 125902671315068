import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import getContentNode from "../utilities/contentNode"

const Heading = ({ nodes, id }) => {
  const node = getContentNode(nodes, id)

  if (!node) {
    return <></>
  }

  const { heading } = node

  return (
    <div className="py-4 mb-6 lg:mx-0 md:mb-12 bg-green c-heading">
      <div className="max-w-3xl mx-auto">
        <div className="mx-4">
          <h1 className="text-2xl tracking-wider text-white uppercase font-extralight md:text-4xl md:text-center">
            {heading}
          </h1>
        </div>
      </div>
    </div>
  )
}

Heading.propTypes = {
  nodes: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
}

export default connect(
  (state) => ({
    nodes: state.app.heading,
  }),
  null
)(Heading)
