import { graphql, useStaticQuery } from "gatsby"

export const useSeparator = () => {
  const { separatorImage } = useStaticQuery(
    graphql`
      query separatorQuery {
        separatorImage: file(relativePath: { eq: "reunaa.png" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP])
          }
        }
      }
    `
  )
  return separatorImage
}
