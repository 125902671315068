import React from "react"
import PropTypes from "prop-types"
import ContentfulContent from "./Content"
import ContentfulHeading from "./Heading"
import ContentfulTeasers from "./Teasers"
import ContentfulSplitImageText from "./SplitImageText"

const componentMap = {
  ContentfulContent,
  ContentfulHeading,
  ContentfulSplitImageText,
  ContentfulTeasers,
}

const ComponentPicker = ({ type, component }) => {
  const As = componentMap[type]

  if (!As) {
    return <></>
  }

  return <As {...component} />
}

export default ComponentPicker

ComponentPicker.propTypes = {
  type: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
}
