import React, { useState, useEffect } from "react"
import getMicrocopy from "../utilities/microcopy"

const STORAGE_KEY = "lehtolainen.hideCookieBanner"

const CookieBanner = () => {
  const [dirty, setDirty] = useState(true)

  const bannerText = getMicrocopy("cookie.banner")
  const buttonText = getMicrocopy("cookie.button")

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEY)) {
      setDirty(false)
    }
  }, [])

  const handleClick = () => {
    setDirty(true)
    localStorage.setItem(STORAGE_KEY, "true")
  }

  return (
    !dirty &&
    bannerText &&
    buttonText && (
      <div className="fixed bottom-0 left-0 right-0 z-20 border-t-2 bg-grey">
        <div className="px-5 py-5">
          <div className="flex flex-col max-w-3xl mx-auto font-light prose md:items-center md:justify-between md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              {bannerText}
            </div>
            <div>
              <button
                onClick={handleClick}
                className="px-4 py-2 text-white border-2 bg-green border-green"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CookieBanner
