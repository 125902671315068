import { graphql, useStaticQuery } from "gatsby"

export const usePage = () => {
  const { allContentfulPage } = useStaticQuery(
    graphql`
      query pageQuery {
        allContentfulPage {
          edges {
            node {
              contentful_id
              slug
            }
          }
        }
      }
    `
  )
  return allContentfulPage.edges
}
