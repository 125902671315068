import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { PropTypes } from "prop-types"
import DynamicLink from "./DynamicLink"
import EmbeddedAsset from "../utilities/richtext/asset"
import HyperlinkResolver from "../utilities/richtext/hyperlink"

const RichText = ({ content }) => {
  const document = JSON.parse(content.raw)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <EmbeddedAsset node={node} content={content} />
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <DynamicLink node={node}>{children}</DynamicLink>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <HyperlinkResolver node={node}>{children}</HyperlinkResolver>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return documentToReactComponents(document, options)
}

export default RichText

RichText.propTypes = {
  content: PropTypes.object.isRequired,
}
