import React from "react"
import Navigation from "./Navigation"
import getMicrocopy from "../utilities/microcopy"

const Footer = () => {
  const copyright = getMicrocopy("footer.copyright")

  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer>
      <div className="px-5 py-10 bg-green">
        <div className="flex flex-col justify-center max-w-5xl mx-auto font-light text-center text-white md:flex-row">
          {copyright && <div className="mx-4">&copy; {copyright} {year}</div>}
          <Navigation navigation="footer" spacing="4" ariaLabel="Toissijainen valikko" />
        </div>
      </div>
    </footer>
  )
}

export default Footer
