import { useMicrocopyData } from "../hooks/microcopy"
import PropTypes from "prop-types"

const getMicrocopy = (key) => {
  const items = useMicrocopyData()
  const microcopy = items
    .filter(({ node }) => node.key === key)
    .map(({ node }) => node.value)
    .join()

  return microcopy
}

export default getMicrocopy

getMicrocopy.prototypes = {
  key: PropTypes.string.isRequired,
}
